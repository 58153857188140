import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import style from "./footer.mod.scss"
import {
  Logo,
  MenuItem,
  Badge,
  Block,
  Button,
  Cards,
  Banner
} from "@components"
import FooterSvg from "@svgs/footer-svg.svg"
import FacebookSvg from "@svgs/facebook.svg"
import TwitterSvg from "@svgs/twitter.svg"
import InstagramSvg from "@svgs/instagram.svg"
import PinterestSvg from "@svgs/pinterest.svg"
import { filterByLocale } from "@helpers/miscHelpers"

const Footer = ({ minimal }) => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      globalConfigAu: allDatoCmsGlobal(locale: "en-AU") {
        nodes {
          locales
          footerMenuDisplay {
            id
            heading
            links {
              ...Link
            }
          }
          trustMarksWhite {
            ...TrustMark
          }
          banner: globalBanner {
            id
            link {
              ...Link
            }
            image {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                imgixParams: {
                  h: "295"
                  w: "1170"
                  fit: "crop"
                  q: 60
                  auto: "format"
                }
              )
              alt
            }
          }
        }
      }
      globalConfigNz: allDatoCmsGlobal(locale: "en-NZ") {
        nodes {
          locales
          footerMenuDisplay {
            id
            heading
            links {
              ...Link
            }
          }
          trustMarksWhite {
            ...TrustMark
          }
          banner: globalBanner {
            id
            link {
              ...Link
            }
            image {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                imgixParams: {
                  h: "295"
                  w: "1170"
                  fit: "crop"
                  q: 60
                  auto: "format"
                }
              )
              alt
            }
          }
        }
      }
      yaml: contentYaml {
        language
        abn
      }
      site {
        siteMetadata {
          facebook
          instagram
          twitter
          pinterest
          title
          websiteUrl
        }
      }
    }
  `)
  const siteLanguage = process.env.GATSBY_LANGUAGE || "en-AU"
  const { abn } = data.yaml

  const localeData =
    siteLanguage === "en-AU"
      ? data.globalConfigAu.nodes[0]
      : data.globalConfigNz.nodes[0]

  console.log(localeData)

  const { footerMenuDisplay, trustMarksWhite, banner } = localeData

  const {
    title,
    facebook,
    instagram,
    pinterest,
    twitter,
    websiteUrl
  } = data.site.siteMetadata

  const socials = [
    {
      url: facebook,
      icon: <FacebookSvg />
    },
    {
      url: twitter,
      icon: <TwitterSvg />
    },
    {
      url: pinterest,
      icon: <PinterestSvg />
    },
    {
      url: instagram,
      icon: <InstagramSvg />
    }
  ]

  const classes = [style.footer]

  minimal && classes.push(style["footer--minimal"])

  const hasHeading = menu => {
    return menu.some(el => el.heading) ? true : false
  }

  return (
    <footer className={classes.join(" ")}>
      {!minimal && (
        <>
          {/* <div className={style.footer__bg}>
            <FooterSvg />
          </div> */}
          <div className={style.footer__upper}>
            <Block padding={"double-bottom"} gutters>
              <Banner
                image={banner[0]?.image}
                alt={banner[0]?.image?.alt}
                mobileImage={banner[0].mobileImage}
                link={banner[0]?.link?.link?.path || banner[0]?.link?.url}
                elevated
              />
            </Block>
            {/* <div className={style.footer__inner}>

            </div> */}
          </div>
          <div className={style.footer__lower}>
            <div className={style.footer__marks}>
              <Block padding="single-top">
                <Cards
                  orientation="horizontal"
                  columns="4"
                  size="large"
                  modifier="footer"
                >
                  {trustMarksWhite.map(
                    ({ icon: { url, alt }, id, heading }) => (
                      <Badge
                        image={url}
                        alt={alt}
                        key={`trustmark-${id}`}
                        heading={heading}
                        moduleStyle="trustmark"
                      />
                    )
                  )}
                </Cards>
              </Block>
            </div>
            <div className={style.footer__inner}>
              <div className={style.footer__logo}>
                <Logo position="footer" />
              </div>
              <div className={style.footer__socials}>
                <h4>Stay Connected</h4>
                <ul className={style.footer__socials_list}>
                  {socials.map(({ url, icon }, i) => (
                    <li
                      key={`social-${i}`}
                      className={style.footer__socials_item}
                    >
                      <Button to={url} unstyled>
                        {icon}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
              <nav className={style.footer__nav}>
                {footerMenuDisplay.map((column, i) => (
                  <ul
                    key={`footer-menu-column-${column.id}`}
                    className={style.footer__nav_list}
                  >
                    {hasHeading(footerMenuDisplay) && (
                      <li className={style.footer__nav_heading}>
                        <h4>{column.heading}</h4>
                      </li>
                    )}
                    {column.links.map((link, i) => (
                      <MenuItem
                        modifier="footer"
                        key={`footer-menu-link-${i}`}
                        {...link}
                        siteUrl={websiteUrl}
                        footer={true}
                      />
                    ))}
                  </ul>
                ))}
              </nav>
            </div>
          </div>
        </>
      )}

      <div className={style.footer__credit}>
        <div className={style.footer__inner}>
          <div className={style.footer__copyright}>
            <p>{`© ${new Date().getFullYear()} ${title}`}</p>
            {abn && <p>{abn}</p>}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
